@tailwind base;
@tailwind components;
@tailwind utilities;


@media (max-width: 600px) {
  table {
    font-size: 0.875rem;
    /* Smaller font size */
  }

  th,
  td {
    padding: 0.5rem;
    /* Reduced padding */
  }
}